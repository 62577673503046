<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">登录日志管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="success" icon="el-icon-refresh" @click="
                p.page = 1;
                f5();
              ">刷新</el-button>
        </el-form-item>
        <br />
      </el-form>
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
        <el-table-column label="会话id" prop="id">
        </el-table-column>
        <el-table-column label="用户昵称" prop="name"></el-table-column>
        <el-table-column label="登录ip" prop="ip"></el-table-column>
        <el-table-column label="登录地点" prop="location"></el-table-column>
        <el-table-column label="浏览器" prop="browser"></el-table-column>
        <el-table-column label="操作系统" prop="system"></el-table-column>
        <el-table-column label="登录时间" prop="time"></el-table-column>
        <el-table-column label="角色">
          <template slot-scope="s">
            <el-tag v-if="s.row.roleId == 1" size="success">商户</el-tag>
            <el-tag v-if="s.row.roleId == 2" size="warning">管理员</el-tag>
            <el-tag v-if="s.row.roleId == 3" size="warning">码队</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="登录状态">
          <template slot-scope="s">
            <el-tag v-if="s.row.status == 1" size="success">在线</el-tag>
            <el-tag v-if="s.row.status == 2" size="danger">离线</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="danger" icon="el-icon-switch-button" @click="forceLogout(s.row)">强制退出</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      p: {
        page: 1,
        limit: 10,
      },
      dataCount: 0,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "post", // 默认请求类型
      };
      this.sa.ajax(
        "/v2/admin/getLoginLog",
        this.p,
        function (res) {
          this.dataList = res.data.content; // 数据
          this.dataCount = res.data.totalElements; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    forceLogout: function (row) {

      var p = {
        id: row.id
      }
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.sa.ajax(
        "/v2/admin/forceLogout",
        p,
        function () {
          this.sa.ok("强制退出成功");
        }.bind(this),
        defaultCfg
      );
    }
  },
  created: function () {
    this.f5();
  },
};
  </script>
  
  <style>
</style>
  